<div [formGroup]="scoreForm" class="d-flex flex-column">
  <p class="mb-1 text-muted">
    <label for="name" i18n="@@field.name.label">-</label><span> *</span>
  </p>
  <mat-form-field appearance="fill" class="mb-2">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.name.hint"
      formControlName="lastname"
      id="name"
      required
      data-testid="lastname"
      [requestFocus]="true"
    />
    <mat-error
      role="alert"
      *ngIf="scoreForm.controls['lastname'].errors?.['required']"
      i18n="@@field.required.error"
      >-</mat-error
    >
  </mat-form-field>

  <p class="mb-1 text-muted">
    <label for="firstname" i18n="@@field.firstname.label">-</label><span> *</span>
  </p>
  <mat-form-field appearance="fill" class="mb-2">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.firstname.hint"
      formControlName="firstname"
      id="firstname"
      data-testid="firstname"
      required
    />
    <mat-error
      role="alert"
      *ngIf="scoreForm.controls['firstname'].errors?.['required']"
      i18n="@@field.required.error"
      >-</mat-error
    >
  </mat-form-field>

  <p class="mb-1 text-muted">
    <label for="email" i18n="@@field.email.label">-</label><span> *</span>
  </p>
  <mat-form-field appearance="fill" class="mb-2">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.email.hint"
      formControlName="email"
      id="email"
      data-testid="email"
      required
    />
    <!-- prettier-ignore -->
    <mat-error role="alert"  *ngIf="scoreForm.controls['email'].errors?.['required']" i18n="@@field.required.error">Email requis</mat-error>
    <mat-error
      role="alert"
      *ngIf="
        scoreForm.controls['email'].invalid && !scoreForm.controls['email'].errors?.['required']
      "
      i18n="@@field.email.error"
      >-</mat-error
    >
  </mat-form-field>

  <p class="mb-1 text-muted">
    <label for="userId" i18n="@@field.userId.label">-</label>
  </p>
  <mat-form-field appearance="fill" class="mb-2">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.userId.hint"
      formControlName="userId"
      id="userId"
      data-testid="userId"
    />
  </mat-form-field>

  <p class="mb-1 text-muted">
    <label for="company" i18n="@@field.company.label">-</label>
  </p>
  <mat-form-field appearance="fill" class="mb-2">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.company.hint"
      formControlName="company"
      id="company"
      data-testid="company"
    />
  </mat-form-field>
</div>
